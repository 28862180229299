import http from "./http"

class ReportService {
    async getAll() {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
    async getAllUsers() {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}/users`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
    async getLeadsCurrent() {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}/month/current`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
    async getLeadsPrevious() {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}/month/previous`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
    async getTotalCurrent() {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}/billed/current`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
    async getReportByMonth(month, year) {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}/month/${month}/year/${year}`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
    async getReportByYear(year) {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}/year/${year}`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
    async getAllBilling() {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}/billing/all`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
    async getAllInvestmentsCurrent() {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}/investments/current`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
    async getAllInvestmentsPrevious() {
        var user = JSON.parse(localStorage.getItem('user'));
        return http.get(`/report/${user.id}/investments/previous`)
            .then(async response => {
                return response.data;
            }).catch((err) => {
                console.log(err);
            });
    }
}

export default new ReportService();   
