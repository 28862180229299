import http from "./http"

class AuthenticateService {
    async login(data) {
        return await http.post("/auth/login", data)
        .then(async response => {
            if(response.data.success) {
                const auth = response.data.data;
                localStorage.setItem('user', JSON.stringify(auth));
                await http.get(`/account/${auth.id}`)
                    .then(async result => {
                        if(result.data.success) {
                            const user = result.data.data;
                            localStorage.setItem('user', JSON.stringify(user));
                        }
                        return result;
                    });
            }
            return response;
        });
    }

    refreshToken(data) {
        http.post("/auth/token", data)
                .then(response => {
                    if(response.data.success) {
                        var user = response.data.data;
                        localStorage.setItem('user', JSON.stringify(user));
                    }
                    return response;
            }).catch((err) => {
                console.log(err);
            });
    }

    logout() {
        localStorage.removeItem('user');
    }
}

export default new AuthenticateService();   