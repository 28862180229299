const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
  "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
];

export default {
    getMonth: () => {
        const d = new Date();
        return monthNames[d.getMonth()];
    },
    getPreviousMonth: () => {
      const d = new Date();
      return monthNames[d.getMonth()-1];
  },
  numberToReal: (number) => {
    var number = number.toFixed(2).split('.')
    number[0] = 'R$ ' + number[0].split(/(?=(?:...)*$)/).join('.')
    return number.join(',')
  },
}