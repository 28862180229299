import ReportService from '@/services/report'

export default {
  name: 'Billing',
  data() {
    return {
      items: [],
      itemsOrdered: [],
      fields: [
        { key: 'monthName', label: 'Mês' },
        { key: 'year', label: 'Ano' },
        { key: 'feeType', label: 'Negócio' },
        { key: 'totalLeads', label: 'Leads' },
        { key: 'totalCash', label: 'Faturado' }
      ],
      loading: true
    }
  },
  methods: {
    async getBilling() {
      await ReportService.getAllBilling()
        .then(async result => {
          if(result.success) {
            this.items = result.data;
            if (this.items.length > 0) {
              this.itemsOrdered = this.items.sort((a, b) =>
                a.createDate > b.createDate ? -1 : 1
              )
            }
          }
          this.loading = false;
        });
    },
    numberToReal: (number) => {
      var number = number.toFixed(2).split('.')
      number[0] = 'R$ ' + number[0].split(/(?=(?:...)*$)/).join('.')
      return number.join(',')
    },
    getFeeType(type) {
      switch (type) {
        case 'HCP__PARTIAL':
          return 'Comissão'
        case 'HCP__FULL':
          return 'Fixo'
        default:
          'Não informado'
      }
    },
  },
  mounted() {
    this.getBilling();
  }
}
