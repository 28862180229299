export default {
  authSetData(state, payload) {
    state.root = payload;
  },
  authUnsetData(state) {
    state.root = {};
  },
  authSetDataLang(state, payload) {
    state.lang = payload;
  }
};
