import AccountService from '@/services/account'

export default {
  name: 'Password',
  data() {
    return {
      password: "",
      newpassword: "",
      newpassword2: "",
      loading: true,
      message: "",
      fixedToasts: 0
    }
  },
  methods: {
    async setPassword() {
      if(this.password == "") {
        this.fixedToasts++
        this.message = "Por favor, digite sua senha"
        return;
      }
      if(this.newpassword == "") {
        this.fixedToasts++
        this.message = "Por favor, digite sua nova senha"
        return;
      }
      if(this.newpassword != this.newpassword2) {
        this.fixedToasts++
        this.message = "Por favor, as senhas informadas são diferentes"
        return;
      }      
      if(this.newpassword.length < 6) {
        this.fixedToasts++
        this.message = "Por favor, a senha deve ter no mínimo 6 caracteres"
        return;
      }
      if(this.newpassword.search(/[a-z]/i) < 0) {
        this.fixedToasts++
        this.message = "Por favor, a senha deve ter pelo menos uma letra"
        return;
      }
      if(this.newpassword.search(/[0-9]/) < 0) {
        this.fixedToasts++
        this.message = "Por favor, a senha deve ter pelo menos um número"
        return;
      }
      const data = {
        password: this.password,
        newpassword: this.newpassword
      }
      await AccountService.password(data)
      .then(async result => {
        if(result.data.success) {
          this.items = result.data;
          this.$router.push({ name: "Dashboard"});
        } else {
          this.message = result.data.data.message;
          this.fixedToasts++
        }
        this.loading = false;
      });
    },
    addFixedToast () {
      this.fixedToasts++
    }
  },
  mounted() {
    
  }
}
