var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticStyle:{"width":"100%","height":"fit-content"}},[_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.itemsOrdered,"fields":_vm.fields,"column-filter":"","table-filter":{
        placeholder: 'Digite o campo que procura'
      },"noItemsView":{
        noResults: 'Nenhum Resultado encontrado',
        noItems: 'Nenhum item ativo'
      },"items-per-page-select":{ label: 'Itens por página' },"items-per-page":10,"hover":"","sorter":"","pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"feeType",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getFeeType(item.feeType)))])]}},{key:"totalCash",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.numberToReal(item.totalCash)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }