export default {
  root: {
    token: "",
    refreshToken: "",
    accountId: "",
    exp: 0,
    isAuth: false
  },
  lang: {
    countryCode: "br"
  }
};
