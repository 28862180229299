import axios from "axios";
import AuthenticateService from "./authenticate";

const corsHeaders = {
  Accept: 'application/json, application/pdf, application/octet-stream',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Headers': '*',
  'Access-Control-Allow-Methods': 'DELETE, GET, POST, PATCH, PUT',
  'Access-Control-Allow-Origin': '*',
  'X-Content-Type-Options': 'nosniff',
  'x-account-admin': true,
};

const securityHeaders = {
  'Content-Security-Policy': "default-src https frame-ancestors 'none'",
  'Referrer-Policy': 'same-origin',
  'X-Frame-Options': 'DENY',
  'X-XSS-Protection': '1; mode=block',
};

let baseURL = "/api"

baseURL = 'https://partner.hurst.capital/api/'

let baseHurst = 'https://api-hcp.hurst.capital/v2/'

export const httpHcp = axios.create({
  baseURL: baseHurst,
});

export const http = axios.create({
  baseURL,
  headers: {
    ...corsHeaders,
    ...securityHeaders,
  },
});

http.interceptors.request.use((config) => {
  const conf = config;
  let user = JSON.parse(localStorage.getItem('user'));

  if (user) {
    conf.headers.Authorization = user.accessToken;
    conf.headers['x-account-id'] = user.id;
    conf.headers['x-account-admin'] = true
  }

  return conf;
}, (error) => {
  const erro = error;
  erro.response.body = error.response.error;
  delete erro.response.error;

  return erro.response;
});

/*http.interceptors.response.use((response) => {
  console.log("resposta");
  console.log(response);
  return response;
}, (error) => {
  console.log("entrou no erro");
  //console.log(JSON.stringify(error.response))
  //localStorage.removeItem('user');
  //this.$router.push({ name: "Login" });
  //window.location =  '/login';
  let erro = {};
  if (error.response) {
    erro = {
      data: {
        success: false,
        data: error.response.data.err,
      },
    };
  } else {
    erro = {
      data: {
        success: false,
        data: 'Network error',
      },
    };
  }
  return erro;
});*/

http.interceptors.response.use(
  response => response,
  error => {
    //console.log(JSON.stringify(error.response))
    //localStorage.removeItem('user');
    //this.$router.push({ name: "Login" });
    //window.location =  '/login';

    const originalRequest = error.config;

    const status = error.response.status;

    let erro = {};

    try {
      if (status === 401 && !originalRequest._retry) {
        let userFirst = JSON.parse(localStorage.getItem('user'));
        const data = {
          nid: userFirst.nid,
          refresh_token: userFirst.refresh_token
        }
        AuthenticateService.refreshToken(data);
        //let user = JSON.parse(localStorage.getItem('user'));
        const config = error.config;
        http.request(config)
          .then(response => {
            console.log(response);
            return response;
          });
      } else {
        erro = {
          data: {
            success: false,
            data: error.response.data.err,
          },
        };
      }
      return erro;
    } catch (err) {
      return Promise.reject(err);
    }
  });

export default http;