<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="value => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/dashboard">
      <img class="icon-bank c-sidebar-brand-full" height="40" src="@/assets/logo.png" /> 
      <img class="icon-bank c-sidebar-brand-minimized" height="40" src="@/assets/logo-small.png" /> 
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav" />
  </CSidebar>
</template>

<script>
import nav from './_nav'
export default {
  name: 'Sidebar',
  nav,
  computed: {
    show() {
      return this.$store.state.sidebar.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebar.sidebarMinimize
    }
  }
}
</script>
