import Vue from 'vue'
import Vuex from 'vuex'

import account from "@/store/modules/account/index";
import lead from "@/store/modules/account/index";
import auth from "@/store/modules/auth/index";
import sidebar from "@/store/modules/sidebar/index";

const storeConfig = {
  modules: {
    account,
    auth,
    lead,
    sidebar
  }
};

Vue.use(Vuex)
const store = new Vuex.Store(storeConfig)

export default store
