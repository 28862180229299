<template>
  <CCard>
    <CCardBody>
      <CRow class="card-leads">
        <CCol>
          <p class="card-title mb-0">{{ title.toUpperCase() }}</p>
          <p class="card-subtitle mb-0">{{ subtitle.toUpperCase() }}</p>
          <h3 class="new-leads">{{ value }}</h3>
        </CCol>
        <CIcon class="new-leads-icon" :name="icon" :height="height" />
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'LeadCard',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.card-leads {
  display: flex;
  margin-right: 0;
  justify-content: space-between;
  .card-title {
    white-space: nowrap;
    text-align: start;
    font-weight: bold;
    opacity: 0.6;
  }
  .card-subtitle {
    white-space: nowrap;
    text-align: start;
    font-weight: bold;
    opacity: 0.4;
    font-size: 12px;
    margin-top: -3px;
  }
  .new-leads {
    font-weight: bold;
    text-align: start;
    white-space: nowrap;
  }
  .new-leads-icon {
    opacity: 0.6;
  }
}
</style>
