var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-app"},[_c('LeadsSummary',{attrs:{"currentTotal":_vm.currentTotal,"previousTotal":_vm.previousTotal,"currentCash":_vm.currentInvestiments,"previousCash":_vm.previousInvestiments}}),_c('CCard',{staticStyle:{"width":"100%","height":"fit-content"}},[_c('CCardHeader',[_vm._t("header",[_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" Operações abertas ")])],2),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.itemsOperations,"fields":_vm.fieldsOperation,"column-filter":"","table-filter":{
          placeholder: 'Digite o campo que procura'
        },"noItemsView":{
  noResults: 'Nenhum Resultado encontrado',
  noItems: 'Nenhum item ativo'
},"items-per-page-select":{ label: 'Itens por página' },"items-per-page":10,"hover":"","sorter":"","pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":("https://hurst.capital/operation/" + (item.id)),"target":"_blank"}},[_vm._v(_vm._s(item.name))])])]}},{key:"endDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.transformToDate(item.endDate)))])]}},{key:"category",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getCategory(item.category)))])]}}])})],1)],1),_c('LineChart'),_c('CCard',{staticStyle:{"width":"100%","height":"fit-content"}},[_c('CCardHeader',[_vm._t("header",[_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" Clientes ")])],2),_c('CTabs',{attrs:{"fade":"","active-tab":0,"active-nav-item-class":"text-danger"}},[_c('CTab',{attrs:{"title":"Ordem de criação"}},[_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.usersOrdered,"fields":_vm.fields,"column-filter":"","table-filter":{
          placeholder: 'Digite o campo que procura'
        },"noItemsView":{
  noResults: 'Nenhum Resultado encontrado',
  noItems: 'Nenhum item ativo'
},"items-per-page-select":{ label: 'Itens por página' },"items-per-page":10,"hover":"","sorter":"","pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"firstName",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{on:{"click":function($event){return _vm.showModalDetails(item)}}},[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])])]}},{key:"createDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.transformToDate(item.createDate)))])]}},{key:"profile",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.profile)}},[_vm._v(" "+_vm._s(_vm.getProfile(item.profile))+" ")])],1)]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getTotal(item)))])]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(" "+_vm._s(Boolean(item._toggled) ? 'Hide' : 'Show')+" ")])],1)]}}])})],1)],1),_c('CTab',{attrs:{"title":"Ordem de transação"}},[_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.operationsOrdered,"fields":_vm.fields,"column-filter":"","table-filter":{
          placeholder: 'Digite o campo que procura'
        },"noItemsView":{
  noResults: 'Nenhum Resultado encontrado',
  noItems: 'Nenhum item ativo'
},"items-per-page-select":{ label: 'Itens por página' },"items-per-page":10,"hover":"","sorter":"","pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"firstName",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{on:{"click":function($event){return _vm.showModalDetails(item)}}},[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])])]}},{key:"createDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.transformToDate(item.createDate)))])]}},{key:"profile",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.profile)}},[_vm._v(" "+_vm._s(_vm.getProfile(item.profile))+" ")])],1)]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getTotal(item)))])]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(" "+_vm._s(Boolean(item._toggled) ? 'Hide' : 'Show')+" ")])],1)]}}])})],1)],1)],1)],1),(_vm.showModal)?_c('CModal',{attrs:{"size":"xl","show":_vm.showModal,"no-close-on-backdrop":true,"centered":true,"title":"Modal title 2"},on:{"update:show":function($event){_vm.showModal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v("Investimentos")])]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){_vm.showModal = false}}},[_vm._v("Fechar")])]},proxy:true}],null,false,3792037605)},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-sm-12"},[_c('label',{staticClass:"d-block font-weight-bold"},[_vm._v("Nome")]),_c('label',[_vm._v(_vm._s(_vm.fullName))])])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"d-block font-weight-bold"},[_vm._v("Investimentos")]),_c('CDataTable',{attrs:{"items":_vm.itemsOrdered,"fields":_vm.fieldsItems,"noItemsView":{
          noResults: 'Nenhum Resultado encontrado',
          noItems: 'Nenhum item ativo'
        },"items-per-page-select":{ label: 'Itens por página' },"items-per-page":10,"hover":"","sorter":"","pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"operationName",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.operationName)+" ")])]}},{key:"dateInvested",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.transformToDate(item.dateInvested)))])]}},{key:"amountInvested",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.convertToValue(item.amountInvested))+" ")])]}},{key:"show_details",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(" "+_vm._s(Boolean(item._toggled) ? 'Hide' : 'Show')+" ")])],1)]}}],null,false,2672794653)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }