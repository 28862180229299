import http from "./http"

class AccountService {
    async password(data) {
        var user = JSON.parse(localStorage.getItem('user'));
        return await http.post(`/account/${user.id}/password`, data)
            .then(async result => {
                if(result.data.success) {
                    const user = result.data.data;
                    localStorage.setItem('user', JSON.stringify(user));
                }
                return result;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }
}

export default new AccountService();   