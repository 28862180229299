import AuthenticateService from '@/services/authenticate'

export default {
  name: "Login",
  data() {
    return {
      userData: {
        email: "",
        password: ""
      },
      errorLogin: []
    };
  },
  computed: {
    isButtonLoginDisabled() {
      if (this.userData.email && this.userData.password) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    async checkForm() {
      this.errorLogin = [];
      await AuthenticateService.login(this.userData)
        .then(async result => {
          if (result && result.data && result.data.success) {
            this.$router.push({
              name: "Dashboard"
            });
          } else {
            this.errorLogin.push(
              "Senha ou usuário errado, por favor tente novamente."
            );
          }
        });
    }
  }
};
