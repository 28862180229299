import ReportService from '@/services/report'
import OperationService from '@/services/operation'
import common from "@/helpers/common";
import moment from 'moment'
import LineChart from '@/components/LineChart/index.vue'
import LeadsSummary from '@/components/LeadsSummary/index.vue'

export default {
  name: 'Dashboard',
  components: {
    LineChart,
    LeadsSummary
  },
  data() {
    return {
      items: [],
      usersOrdered: [],
      itemsOperations: [],
      fields: [
        { key: 'firstName', label: 'Nome', _style: 'width: 200px' },
        { key: 'createDate', label: 'Criado em' },
        { key: 'profile', label: 'Perfil' },
        { key: 'total', label: 'Total investido' }
      ],
      fieldsOperation: [
        { key: 'name', label: 'Nome da Operação', _style: 'width: 300px' },
        { key: 'assetSymbol', label: 'Simbolo da Moeda' },
        { key: 'category', label: 'Categoria' },
        { key: 'endDate', label: 'Data de fechamento' },
      ],
      itemsOrdered: [],
      fieldsItems: [
        { key: 'operationName', label: 'Operação' },
        { key: 'dateInvested', label: 'Investido em' },
        { key: 'amountInvested', label: 'Valor' }
      ],
      currentTotal: '0',
      previousTotal: '0',
      currentInvestiments: '0',
      previousInvestiments: '0',
      currentCash: common.numberToReal(0),
      previousCash: common.numberToReal(0),
      loading: true,
      showModal: false,
      fullName: "",
    }
  },
  methods: {
    async getLeads() {
      await ReportService.getAll()
        .then(async result => {
          if (result.success) {
            this.items = result.data;
            if (this.items.length > 0) {
              this.itemsOrdered = this.items.sort((a, b) =>
                a.createDate > b.createDate ? -1 : 1
              )
            }
          }
          this.loading = false;
        });
    },
    async getUsers() {
      await ReportService.getAllUsers()
        .then(async result => {
          if (result.success) {
            this.items = result.data;
            if (this.items.length > 0) {
              this.usersOrdered = this.items.sort((a, b) =>
                a.createDate > b.createDate ? -1 : 1
              )
            }
          }
          this.loading = false;
        });
    },
    async getTotalLeadsCurrent() {
      await ReportService.getLeadsCurrent()
        .then(async result => {
          if (result.success) {
            this.currentTotal = result.data.total.toString();
          }
        });
    },
    async getTotalLeadsPrevious() {
      await ReportService.getLeadsPrevious()
        .then(async result => {
          if (result.success) {
            this.previousTotal = result.data.total.toString();
          }
        });
    },
    async getTotalCurrent() {
      await ReportService.getTotalCurrent()
        .then(async result => {
          if (result.success) {
            this.currentCash = common.numberToReal(result.data.total);
          }
        });
    },
    async getTotalPrevious() {
      const d = new Date();
      await ReportService.getReportByMonth(d.getMonth(), d.getFullYear())
        .then(async result => {
          if (result.success) {
            if (result.data.length > 0) {
              this.previousCash = common.numberToReal(result.data[0].totalCash);
            }
          }
        });
    },
    async getInvestimentsCurrent() {
      await ReportService.getAllInvestmentsCurrent()
        .then(async result => {
          if (result.success) {
            this.currentInvestiments = common.numberToReal(result.data.total);
          }
        });
    },
    async getInvestimentsPrevious() {
      await ReportService.getAllInvestmentsPrevious()
        .then(async result => {
          if (result.success) {
            this.previousInvestiments = common.numberToReal(result.data.total);
          }
        });
    },
    getBadge(status) {
      switch (status) {
        case 'HCP__LEAD':
          return 'success'
        case 'HCP__QUALIFIED':
          return 'success'
        case 'HCP__COSTUMER':
          return 'secondary'
        case 'HCP__INVESTOR':
          return 'warning'
        case 'HCP__PRIME':
          return 'danger'
        default:
          'primary'
      }
    },
    getProfile(status) {
      switch (status) {
        case 'HCP__LEAD':
          return 'Lead'
        case 'HCP__QUALIFIED':
          return 'success'
        case 'HCP__COSTUMER':
          return 'Cliente'
        case 'HCP__INVESTOR':
          return 'Investidor'
        case 'HCP__PRIME':
          return 'Prime'
        default:
          'Sem perfil'
      }
    },
    getCategory(category) {
      switch (category) {
        case 'HCP__MUSICAL_ROYALTIES':
          return 'ROYALTIES MUSICAIS'
        case 'HCP__REAL_STATE':
          return 'ATIVOS IMOBILIÁRIOS'
        case 'HCP__WORKS_OF_ART':
          return 'OBRAS DE ARTE'
        case 'HCP__JUDICIAL':
          return 'ATIVOS JUDICIAIS'
        case 'HCP__CORPORATE':
          return 'ATIVOS EMPRESARIAIS'
        case 'HCP__CRYPTO':
          return 'CRYPTO'
        case 'HCP__BIO':
          return 'BIOTECNOLOGIA'
        default:
          'SEM CATEGORIA'
      }
    },
    getTotal(item) {
      var sum = 0
      if (item.investments) {
        for (var i = 0; i < item.investments.length; i++) {
          sum += item.investments[i].amountInvested;
        }
      }
      return common.numberToReal(sum)
    },
    toggleDetails(item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => {
        this.collapseDuration = 0
      })
    },
    transformToDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    showModalDetails(item) {
      if (item.investments && item.investments.length > 0) {
        this.showModal = true;
        this.fullName = item.firstName + " " + item.lastName
        this.itemsOrdered = item.investments.sort((a, b) =>
          a.dateInvested > b.dateInvested ? -1 : 1
        )
      }
    },
    convertToValue(value) {
      return common.numberToReal(value)
    },
    async getOperations() {
      const response = await OperationService.getOpenOperations()
      if (response.data.success) {
        this.itemsOperations = response.data.data;
        if (this.itemsOperations.length > 0) {
          this.itemsOperations = this.itemsOperations.sort((a, b) =>
            b.endDate > a.endDate ? -1 : 1
          )
        }
        this.itemsOperations = this.itemsOperations.filter((item) => {
          return item.status === 'HCP__OPEN'
        })
      }
    },
  },
  computed: {
    operationsOrdered() {
      this.usersOrdered.forEach((value) => {
        if (value.investments && value.investments.length > 0) {
          value.investments.sort((a, b) =>
            a.dateInvested > b.dateInvested ? -1 : 1
          )
        }
      })
      return this.usersOrdered.map((item) => {
        return ({
          dateInvested: (!item.investments)
            ? '2019-08-25T18:02:46.183Z'
            : item.investments[0].dateInvested,
          firstName: item.firstName,
          lastName: item.lastName,
          fullName: item.firstName + " " + item.lastName,
          createDate: item.createDate,
          profile: item.profile,
          total: item.total,
          investments: item.investments
        })
      }).sort((a, b) =>
        a.dateInvested > b.dateInvested ? -1 : 1
      )
    }
  },
  mounted() {
    //this.getLeads();
    this.getTotalLeadsCurrent();
    this.getTotalLeadsPrevious();
    this.getInvestimentsCurrent();
    this.getInvestimentsPrevious();
    this.getUsers();
    this.getOperations();
    setTimeout(() => {
      this.isLoading = false
    }, 1000)
  }
}
