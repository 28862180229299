export default {
  toggleSidebarDesktop(state) {
    state.sidebarMinimize = !state.sidebarMinimize
  },
  toggleSidebarMobile(state) {
    state.sidebarMinimize = !state.sidebarMinimize
  },
  set(state, [variable, value]) {
    state[variable] = value
  }
}
