import ReportService from '@/services/report'
import { CChartLine } from "@coreui/vue-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils/src";
export default {
  name: "LineChart",
  components: { CChartLine },
  data() {
    return {
      selectedTuple: [],
      tuple: [
        {
          year: 2021,
          data: [130, 39, 10, 50, 30, 70, 35, 130, 39, 10, 50, 130, 70],
        },
        {
          year: 2020,
          data: [170, 135, 130, 139, 110, 170, 135, 130, 139, 110, 50, 130, 70],
        },
      ],
      labels: ["Janeiro", "Mar"],
      years: 0,
    };
  },
  computed: {
    defaultDatasets() {
      const brandInfo = getStyle("info") || "#20a8d8";
      return [
        {
          label: "Total do mês",
          backgroundColor: hexToRgba(brandInfo, 10),
          borderColor: brandInfo,
          pointHoverBackgroundColor: brandInfo,
          pointBackgroundColor: hexToRgba(brandInfo, 25),
          pointBorderColor: hexToRgba(brandInfo, 25),
          borderWidth: 2,
          lineTension: 0.4,
          data: this.selectedTuple.data,
        },
      ];
    },
    defaultLabels() {
      return this.labels;
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                stepSize: Math.ceil(250 / 5)
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
        elements: {
          point: {
            radius: 4,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      };
    },
  },
  methods: {
    async setYear(year) {
      await ReportService.getReportByYear(year)
        .then(async result => {
          if (result.success) {
            const arr = result.data.sort((a, b) => (a.month < b.month ? -1 : 1));;
            if (arr.length > 0) {
              var item = new Object();
              item.year = year;
              item.data = arr.map(function (ele) { return ele.totalLeads; })
              this.selectedTuple = item;
              this.labels = arr.map(function (ele) { return ele.monthName; })
            }
          }
        });
    },
  },
  mounted() {
    this.years = [2021, 2022];
    this.setYear(this.years[1]);
  }
};
