import mockData from "@/mock.json";
export default {
  login: async ({ commit }, payload) => {
    // const mockedData = JSON.parse(mockData);
    // const user = {
    //   userName: "userTest",
    //   userPassword: "test123"
    // };
    if (
      payload.name === mockData.userName &&
      payload.password === mockData.userPassword
    ) {
      commit("authSetData", payload);
      return true;
    } else {
      return false;
    }
  }
};
