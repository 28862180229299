<template>
  <CFooter :fixed="false">
    <div hidden>
      <a href="https://hurst.capital" target="_blank">Hurst Capital</a>
      <span class="ml-1"
        >&copy; {{ new Date().getFullYear() }}.</span
      >
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://hurst.capital">Hurst Capital</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
