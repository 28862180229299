import Vue from 'vue'
import VueRouter from 'vue-router'

// import Home from "@/views/Home.vue";
import Login from "@/views/login/login.vue";
import NotFound from "@/views/NotFound.vue";
import Dashboard from "@/views/dashboard/dashboard.vue";
import Password from "@/views/password/password.vue";
import Billing from "@/views/billing/billing.vue";
const PageContainer = () => import("@/views/PageContainer.vue");

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "",
    redirect: "/login",
    component: PageContainer,
    meta: {
      loggedIn: true
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "password",
        name: "Alterar senha",
        component: Password,
      },
      {
        path: "billing",
        name: "Faturamento",
        component: Billing,
      },
    ],
  },
  {
    path: '/login',
    component: Login,
    name: "login",
  },
  {
    path: '/logout',
    beforeEnter(to, from, next) {
      localStorage.clear()
      next('/')
    }
  },
  {
    path: '/404',
    component: NotFound,
    name: "404",
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem('user'));

  // if (to.matched.some(record => record.meta.loggedIn)) {
  //   if (!loggedIn || loggedIn.exp * 1000 < new Date().getTime()) return next('/login');
  // }
  // trying to access a restricted page + not logged in 
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
