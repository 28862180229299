<template>
  <CRow>
    <CCol sm="6" md="12" lg="6" xl="3">
      <LeadCard :title="'Novos clientes'" :subtitle="month" :value="currentTotal" :icon="'cil-user-follow'"
        :height="52"></LeadCard>
    </CCol>
    <CCol sm="6" md="12" lg="6" xl="3">
      <LeadCard :title="'TOTAL DE INVESTIMENTOS'" :subtitle="month" :value="currentCash" :icon="'cil-dollar'"
        :height="58">
      </LeadCard>
    </CCol>
    <CCol sm="6" md="12" lg="6" xl="3">
      <LeadCard :title="'Novos clientes'" :subtitle="previousMonth" :value="previousTotal" :icon="'cil-user-follow'"
        :height="52"></LeadCard>
    </CCol>
    <CCol sm="6" md="12" lg="6" xl="3">
      <LeadCard :title="'TOTAL DE INVESTIMENTOS'" :subtitle="previousMonth" :value="previousCash" :icon="'cil-dollar'"
        :height="58"></LeadCard>
    </CCol>
  </CRow>
</template>

<script>
import common from "@/helpers/common";
import LeadCard from '@/components/LeadCard/index.vue'
export default {
  name: 'LeadsSummary',
  props: {
    currentTotal: {
      type: String,
      required: false,
      default: '0'
    },
    previousTotal: {
      type: String,
      required: false,
      default: '0'
    },
    currentCash: {
      type: String,
      required: false,
      default: '0'
    },
    previousCash: {
      type: String,
      required: false,
      default: '0'
    },
  },
  components: {
    LeadCard
  },
  data() {
    return {
      month: common.getMonth(),
      previousMonth: common.getPreviousMonth()
    }
  }
}
</script>

<style scoped lang="scss">
@media only screen and (max-width: 1600px) and (min-width: 768px) {
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
